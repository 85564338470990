


.default-card-header {
  text-align: center;
  padding-top: 1.5rem;
  color: #0077C8;
  font-family: Poppins, sans-serif !important;
  font-weight: 700 !important;
}

.default-cancel-icon {
  position: absolute;
  color: #c4c4c4;
  top: 1rem;
  right: 1rem;
}

.default-cancel-icon:hover {
  color: #dbdbdb;
  cursor: pointer;
}
