@import "../01-Base/colours";

.default-table {
  table {
    width: 100%;
    font-size: 0.8rem;

    tr {
      height: 1.8rem;
    }
  }

  .table-header {
    td {
      font-size: 0.9rem;
    }
  }
}


.neat-table {

  table {
    border-collapse: collapse;
    font-family: Poppins;
    width: 100%;

    tr {
      height: 2.5rem;
      border-bottom: 1px solid #e8e8e8;

      td {
        font-size: 0.875rem;
        font-weight: 400;
      }

      td:first-of-type {
        padding-left: 0.5rem;
      }
    }

    thead {
      background-color: $primary;

      tr {
        background-color: $primary;
        position: sticky;
        color: $grey-light;
        top: 0;

        td {
          font-size: 0.9rem;
        }

      }
    }
  }
}

.sticky-table {
  table {
    border-collapse: collapse;
    width: 100%;

    tr {
      border-bottom: 1px solid #e8e8e8;
      height: 1.8rem;

      td {
        font-size: 0.8rem;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }

    thead {
      tr {
        position: sticky;
        top: 0;
        background-color: white;

        td {
          font-size: 0.9rem;
          color: $primary;
        }
      }
    }

    tbody {
      tr {
        td {
          color: $dark-grey;
        }
      }
    }
  }
}

.default-colon-td {
  width: 2rem;
  padding-right: 0.5rem;

  span {
    float: right;
  }
}

._40-60-td {
  td:first-child {
    width: 40%;
  }

  td:nth-child(2) {
    width: 60%;
  }
}