@import "../01-Base/colours";

.default-unordered-list {
  li {
    display: flex;
    align-items: center;
    line-height: 25px;

    span {
      text-align: left;
      &:first-of-type {
        color: $grey;
        width: 25%;
      }
      &:nth-of-type(2) {
        color: $primary;
        font-weight: 500;
      }
    }
  }
}

.default-content-spacing {
  > * {
    margin-bottom: 1rem;
    display: block;
  }
}
