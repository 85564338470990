.default-button-size {
  width: 7rem;
  height: 2rem;
}

.default-button-width {
  width: 7rem;
}

.default-button-height {
  height: 2rem;
}

.rain-button {
  border: 2px solid #0077c8;
  margin-bottom: 0.25em;
  border-radius: 50px;
  background-color: white;
  color: #0077c8 !important;
  position: relative;
  font-weight: 500;
  font-size: 0.7rem;
  font-family: "Poppins", sans-serif !important;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;

  &.solid {
    background-color: #0077c8;
    color: white !important;
  }
}

.rain-button:hover {
  background-color: #0077c8;
  color: white !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.solid {
    background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
  }
}

.rain-button:disabled {
  border: 2px solid #c4c4c4;
  color: #c4c4c4 !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.blank-button {
  margin-bottom: 0.25em;
  border-radius: 50px;
  position: relative;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif !important;
}

.blank-button:hover {
  cursor: pointer;
}

.blank-button:disabled,
.blank-button:hover:disabled {
  border: 2px solid #c4c4c4;
  color: #c4c4c4 !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.grey-button {
  background-color: #5f5f5f;
  border: 2px solid #5f5f5f;
  color: white;
}

/* Decline and accept buttons */

.decline-button {
  border: 2px solid #e02020;
  color: white !important;
  background-color: #e02020;

  &.solid {
    background-color: #e02020;
    opacity: 1;
    color: white !important;
  }

  mat-icon {
    margin-bottom: 0.3rem;
  }
}

.decline-button:hover {
  background-color: #ec2a2a;
}

.decline-button:disabled,
.decline-button:hover:disabled {
  border: 2px solid #c4c4c4;
  color: #c4c4c4 !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.accept-button {
  border: 2px solid #2acc7f;
  color: white !important;
  background-color: #2acc7f;
}

.pause-button {
  border: 2px solid rgb(247, 178, 0);
  color: white !important;
  background-color: rgb(247, 178, 0);
}

.accept-button:hover {
  background-color: #27da83;
}

.accept-button:disabled,
.accept-button:hover:disabled {
  border: 2px solid #c4c4c4;
  color: #c4c4c4 !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.pause-button:hover {
  background-color: rgb(247, 178, 0);
}

.rain-button {
  font-family: "Poppins", sans-serif !important;
}

.rain-button-promo {
  border: none;
  margin-bottom: 0.25em;
  border-radius: 50px;
  background-color: white;
  color: black !important;
  position: relative;
  font-weight: 500;
  font-size: 0.7rem;
  font-family: "Poppins", sans-serif !important;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;

  &.solid {
    background: radial-gradient(53.81% 53.77% at 86.29% 105.67%, #F6D663 43%, #EFAF57 96%);
  }
}

.rain-button-promo:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.solid {
    background: radial-gradient(53.81% 53.77% at 86.29% 105.67%, #f6d663c2 43%, #efb057c7 96%);
  }
}

.rain-button-promo:disabled {
  border: 2px solid #EFAF57;
  color: rgba(0, 0, 0, 0.548) !important;
  background: radial-gradient(53.81% 53.77% at 86.29% 105.67%, #f6d663c2 43%, #efb057c7 96%);
  cursor: not-allowed;
}
