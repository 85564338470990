.gm-style-iw {
  margin: 0;
  padding: 0;
  border: 2px solid #0283cf;
}

.gm-style-iw.gm-style-iw-c {
  background-color: #191919 !important;
  button {
    display: none !important;
  }
}

.gm-style div div div div div div div div {
  display: inline-block;
  color: #0283cf;
  font-size: 13px;
  font-weight: bold;
  box-shadow: none;
}

.gm-style-iw-d {
  overflow: visible !important;

  div {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 12px;
  }
}

.gm-style div div div div div div div div::after {
  background-color: #0283cf !important;
}
