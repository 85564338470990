// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$snowflake-primary: mat-palette($mat-indigo);
$snowflake-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$snowflake-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$snowflake-theme: mat-light-theme((color: (primary: $snowflake-primary,
        accent: $snowflake-accent,
        warn: $snowflake-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($snowflake-theme);

@import "styles/01-Base/base-dir";
@import "styles/02-Tools/tools-dir";
@import "styles/03-layouts/layouts-dir";
@import "styles/04-google-maps/google_maps.dir";


/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

html {
  background-color: white;
}

// #fafafa
body {
  margin: 0;
  font-family: Poppins !important;
}

/*_____________ basic styles ______________*/

.text {
  color: rgb(189, 190, 193);
  font-weight: 600 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.fill-content {
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-blue {
  color: #0077c8;
}

.text-light-grey {
  color: #c4c4c4;
}

.text-grey {
  color: #b2b2b2;
}

.text-dark-grey {
  color: #757575;
}

.text-darkest-grey {
  color: #424242;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.border {
  border: 2px solid #0283cf;
  border-radius: 10px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.green {
  color: rgb(77, 194, 116);
}

.orange {
  color: rgb(247, 160, 0);
}

.red {
  color: #e02020;
}

.grey-bg {
  background-color: rgb(247, 247, 247);
}

.card-blue-background {
  background: rgb(0, 58, 200);
}

.lighter-blue-background {
  background: #afdaf7;
}

.center {
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none !important;
}

.shadow {
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.16);
}

.flex-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.pointer:hover {
  cursor: pointer;
}

.default-spacer {
  flex: 1 1 auto;
}

.center-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.google-maps-label {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 4px;
}

.multiToolTip {
  white-space: pre-line;
}
