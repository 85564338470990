
  pre {
    outline: 1px solid #cccccc42;
    padding: 25px;
    margin: 5px;
    text-align: left;

    .string { color: darkseagreen; }
    .number, .date { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: #4a4a4a; }
    
}