

.default-input-wrapper {
  display: flex;
  margin-bottom: 1rem;

  input {
    height: 2rem;
    outline: 0;
    border-width: 0 0 2px;
    border-color: #b2b2b2;
    font-family: Poppins;
  }
  input:focus,
  input:hover {
    border-color: #0077c8;
  }

  mat-icon {
    margin-top: 0.5rem;
  }
}

.textBox {
  width: 14rem;
  border-width: 2px;
  border-radius: 5px;
  border-color: #c4c4c4;
  border-style: solid;
  color: #424242;
  font-weight: 400;
  font-family: "Poppins";
}

