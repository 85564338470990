.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable::-webkit-scrollbar {
  width: 0.5em;
}

.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(2, 131, 207, 0.3);
  -webkit-box-shadow: inset 0 0 5px rgba(2, 131, 207, 0.3);
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: rgb(2, 131, 207);
  overflow: overlay;
}

//  OLD scrollbar
// .scrollable::-webkit-scrollbar-thumb {
//   width: 5px;
//   background-clip: padding-box;
//   border-radius: 50px;
//   background-color: rgb(189, 190, 193);
//   box-shadow: inset 7px 10px 12px #f0f0f0;
//   overflow: overlay;
// }

body::-webkit-scrollbar {
  width: 0.5em;
  height: 1rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(2, 131, 207, 0.3);
  -webkit-box-shadow: inset 0 0 5px rgba(2, 131, 207, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(2, 131, 207);
  outline: 1px solid rgb(2, 131, 207);
}

.default-input {
  input {
    height: 2rem;
    width: 100%;
    outline: 0;
    font-weight: 400 !important;
    font-family: Poppins;
    color: #757575;
    border-width: 0 0 1px;
    border-color: #b2b2b2;
  }
  input:focus,
  input:hover {
    border-color: #0077c8;
  }
  input:hover {
    cursor: text;
  }

  input::placeholder {
    opacity: 0.7;
    color: #b2b2b2;
  }
}
