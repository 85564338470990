@import "./colours";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

body {
  text-align: left;
  
  h1, h2, h3, h4, h5, h6, p, a {
      font-family: Poppins, sans-serif !important;
    }
  }

  .fa {
    font-family: "Font Awesome 5 Free" !important;
  }
  

  h1 {
    color: $primary;
    font-weight: 700;
  }

  h1, h2, h3, h4, h5 {
    color: $primary;
  }

  p {
    color: $grey;
  }

  a {
    color: $primary;
    text-decoration: none;
  }