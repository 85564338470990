


.small-font-size {
  font-size: 0.75rem;
}

.medium-font-size {
  font-size: 0.8rem;
}

.medium-large-font-size {
  font-size: 0.9rem;
}

.large-font-size {
  font-size: 1rem;
}
