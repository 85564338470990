.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #0283cf;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #0283cf;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #0283cf;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #0283cf;
}

.mapboxgl-popup-content {
  height: 1rem;
  text-align: center;
  font-size: 0.8rem;
}
