.default-button {
  background-color: #0283cf;
  color: white;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-direction: row;

  button {
    color: white;
    min-width: 15rem;
    height: 3rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.green-background {
  background-color: rgb(77, 194, 116);
}

.orange-background {
  background-color: rgb(247, 160, 0);
}

.red-background {
  background-color: rgb(247, 12, 55);
}

button:disabled {
  cursor: not-allowed;
}

.link-button {
  font-family: "Poppins", sans-serif !important;
}
