$primary: #0077c8;
$primary-hover: #0896f4;
$secondary: #9e9e9e;
$warning: #ffa500;
$error: #e02020;
$success: #4dc274;
$light: #4d4d4d80;
$grey: #6d7278;
$grey-med-light: #8E8E8E;
$grey-light: #f5f5f5;
$dark-grey: #424242;
$light-blue: #d8e9f5;
$med-blue: #E8F5FF;
$navy: #094067;